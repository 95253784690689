import React from "react"
// import { Link } from "gatsby"
import Layout from "../components/layout"
import Hero from "../components/hero"
import { Helmet } from "react-helmet"
import Img from "../assets/images/bgHeroBusesUrbanos.jpg"
import BlockBuses from '../components/buses/blockTpBuses'
import Data from '../data/tiposBuses'
export default function Buses() {
  return (
    <>
      <Helmet>
        <title>Buses de Intramet</title>
        <meta name="description" content="Buses Intramet"></meta>
      </Helmet>
      <Layout>
        <Hero img={Img} title="buses"></Hero>
        <div className="container">
          <BlockBuses data={Data}></BlockBuses>
        </div>
      </Layout>
    </>
  )
}
